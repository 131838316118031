import { Injectable } from "@angular/core";
// import * as JWT from "jwt-decode";
import jwt_decode from 'jwt-decode';
import { Router } from "@angular/router";
@Injectable()
export class JwtService {
  constructor(private router: Router) {}

  getToken(): string {
    // console.log("Token Access "+this.isTokenAvailable());
    switch (this.isTokenAvailable()) {
      case 0:
        return "";
      case 1:
        return JSON.parse(window.localStorage["AUTH_TOKEN"]).Token;
      case 2:
        return JSON.parse(window.sessionStorage["AUTH_TOKEN"]).Token;
    }
  }

  //get Checkin Id
  getCheckInId(): string {
    if (window.localStorage["CheckIn_TOKEN"]) {
      const decoded = jwt_decode(window.localStorage["CheckIn_TOKEN"]);
      return decoded['CheckInId'];
    } else {
      return null;
    }
  }

  getCheckInDate(): string {
    // console.log("Token Access "+this.isTokenAvailable());

    if (window.localStorage["CheckIn_TOKEN"]) {
      const decoded = jwt_decode(window.localStorage["CheckIn_TOKEN"]);
      return decoded['CheckInDate'];
      //return JSON.parse(window.localStorage["CheckIn_TOKEN"]).Token;
    }
  }

  getCheckOutDate(): string {
    // console.log("Token Access "+this.isTokenAvailable());

    if (window.localStorage["CheckIn_TOKEN"]) {
      const decoded = jwt_decode(window.localStorage["CheckIn_TOKEN"]);
      return decoded['CheckOutDate'];
      //return JSON.parse(window.localStorage["CheckIn_TOKEN"]).Token;
    }
  }

  getUserRole(): string {
    const authToken = this.getToken();
    if (authToken != "") {
      const decoded = jwt_decode(authToken);
      // console.log("Decoded Output" + JSON.stringify(decoded));
      if (decoded['UserStatus'] == "active") {
        //If User Active then Return User Role
        return decoded['RoleStatus'];
      } else {
        return "";
      }
    }
  }

  checkPermissions(perm: string) {
    const authToken = this.getToken();
    if (authToken != "") {
      const decoded = jwt_decode(authToken);
      const permissions = decoded['Permissions'];

      let findIndex = decoded['Permissions'].map(function(e) {
        return e.nameKey;
      }).indexOf(perm);

      console.log(findIndex);
      if (findIndex != -1) {
        return decoded['Permissions'][findIndex].permission;
      } else {
        return false;
      }
    }
  }

  // getUserRoute(): string {
  //   // console.log("User Route"+this.getUserRole());
  //   switch (this.getUserRole()) {
  //     case 1:
  //       return "admin";
  //     case 2:
  //       return "staff";
  //     case 3:
  //       return "consumer";
  //     default:
  //       return "";
  //   }
  // }

  saveToken(data: any, isRemind: boolean = true) {
    // console.log("Remember me " + isRemind);
    // console.log("Remember me " + data);
    if (isRemind) {
      window.localStorage.clear();
      window.localStorage["AUTH_TOKEN"] = JSON.stringify(data);
    } else {
      window.sessionStorage.clear();
      window.sessionStorage["AUTH_TOKEN"] = JSON.stringify(data);
    }
    this.router.navigate(["/portal/admin/dashboard"]);
  }

  deleteToken(panel: string) {
    window.sessionStorage.removeItem("AUTH_TOKEN");
    window.localStorage.removeItem("AUTH_TOKEN");
    window.localStorage.removeItem("bulkPermissions");
    // window.sessionStorage.clear();
    if (!panel) {
      this.router.navigate(["/"]);
    } else if (panel == "portal") {
      this.router.navigate(["/portal"]);
    }
  }

  isTokenAvailable(): number {
    try {
      if (window.localStorage["AUTH_TOKEN"]) {
        const authToken = JSON.parse(window.localStorage["AUTH_TOKEN"]);
        if (authToken.Token) {
          const decoded = jwt_decode(authToken.Token);
          return 1;
        } else {
          return 0;
        }
      } else if (window.sessionStorage["AUTH_TOKEN"]) {
        const authToken = JSON.parse(window.sessionStorage["AUTH_TOKEN"]);
        if (authToken.Token) {
          const decoded = jwt_decode(authToken.Token);
          return 2;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }


  /**
   * Decodes jwttoken
   * @param token
   * @returns
   */
  decodeJwttoken(token){
    if (token) {
      const decoded = jwt_decode(token);
      return decoded;
    }
  }

  isTokenExpired(){
    let current_time = Date.now().valueOf() / 1000;
 console.log(new Date(current_time*1000));
 const jwt = this.decodeJwttoken(window.localStorage["CheckIn_TOKEN"]);
  if ( jwt['exp'] < current_time) {
    return true;
  }
  return false;
  }

  isLoginTokenExpired(){
    let current_time = Date.now().valueOf() / 1000;
 console.log(new Date(current_time*1000));
 const jwt = this.decodeJwttoken(window.localStorage["LogIn_TOKEN"]);
  if ( jwt['exp'] < current_time) {
    return true;
  }
  return false;
  }
}
