import { Component, OnInit } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { MasterService } from "../../services/master.service";
import { GlobalService } from "../../services/global.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  FormControl,
  ValidationErrors
} from "@angular/forms";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-activate-page",
  templateUrl: "./activate-page.component.html",
  styleUrls: ["./activate-page.component.scss"]
})
export class ActivatePageComponent implements OnInit {
  public urlParam: string;
  public form: UntypedFormGroup;
  showLoader: boolean = false;
  constructor(
    private masterService: MasterService,
    public globalService: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    //Setup Default Language Setup for Translate
    let combinedValue = this.globalService.concatenate(
      "portal",
      this.globalService.getuserLangTranslate()
    );
    this.translate.setDefaultLang(combinedValue);
    this.form = this.fb.group({
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]+$"
          )
        ]
      ],
      confirmPassword: [null, [Validators.required, this.checkPassword]]
    });

    //Api for Validate Activate Link
    this.checkActivate();
  }
  ngOnDestroy() {}

  checkActivate() {
    //Check Link is Valid  and User is not Already Exists---------------
    this.globalService.showLoader = true;
    this.urlParam = this.route.snapshot.paramMap.get("userId");
    this.masterService
      .checkActivate({
        EncryptString: this.urlParam
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
          // this.router.navigate(["/"]);
        }
      );
  }

  checkPassword(control: AbstractControl) {
    // if (control.touched) {
    let paswd = control.root.get("password");
    console.log(paswd);
    if (paswd && control.value != paswd.value) {
      return {
        passwordMatch: true
      };
    }
    return null;
    // }
  }

  save(form) {
    // console.log(form);
    if (form) {
      // console.log(this.urlParam);
      this.globalService.showLoader = true;
      this.masterService
        .setStaffPassword({ Param: this.urlParam, Data: form })
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              this.globalService.handleSuccessMessage(response.Message);
              this.showLoader = true;
              setTimeout(() => {
                this.router.navigate(["/portal"]);
              }, 3000);
            } else {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }
  }
}
